<script>
import { GetEventCardById, GetNextEventById, GetUserRsvpAndFollowStatus } from "../../operations.gql";
import { SuawEventCard, SuawModal, SuawEmptyState } from "@suaw/suaw-component-library";
import * as SeriesApi from "../../api";

export default {
  name: "EventCardPipe",
  components: { SuawEventCard, SuawModal, SuawEmptyState },
  props: {
    eventId: {
      type: String,
      required: true
    },
    isJoinTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingEventCardQueriesCount: 0,
      loadingUserStatusQueriesCount: 0,
      resultGetEventCardById: {
        id: "",
        is_virtual: null,
        starts_at: "",
        ends_at: "",
        rsvp_limit: null,
        is_cancelled: null,
        venue_json: null,
        place: null,
        rsvp_count: null,
        event_series: {
          id: "",
          is_cancelled: null,
          organizers: null
        }
      },
      resultGetUserRsvpAndFollowStatus: {
        id: "",
        event_calendars: {
          id: ""
        },
        event_subscriptions: {
          id: ""
        }
      },
      resultGetNextEventById: {
        id: "",
        event_series: {
          id: "",
          slug: "",
          next_event: [
            // {
            //   id: ""
            // }
          ],
          chapter: {
            id: "",
            slug: ""
          }
        }
      },
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null
    };
  },
  apollo: {
    resultGetEventCardById: {
      query: GetEventCardById,
      variables() {
        return {
          eventId: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingEventCardQueriesCount"
    },
    resultGetUserRsvpAndFollowStatus: {
      query: GetUserRsvpAndFollowStatus,
      variables() {
        return {
          eventId: this.eventId,
          seriesId: this.seriesId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated || !this.seriesId || !this.eventId;
      },
      loadingKey: "loadingUserStatusQueriesCount"
    },
    resultGetNextEventById: {
      query: GetNextEventById,
      variables() {
        return {
          eventId: this.eventId,
          endsAfter: new Date().toISOString()
        };
      },
      skip() {
        return !this.eventId;
      }
    }
  },
  computed: {
    isLoadingEventCard() {
      return this.loadingEventCardQueriesCount > 0;
    },
    isLoadingUserStatus() {
      return this.loadingUserStatusQueriesCount > 0;
    },
    hasEvent() {
      return !!this.resultGetEventCardById.id && !this.isLoading;
    },
    event() {
      return this.hasEvent ? this.resultGetEventCardById : null;
    },
    seriesCancelled() {
      return this.event?.event_series?.is_cancelled;
    },
    isVirtual() {
      return this.hasEvent ? this.event.is_virtual : null;
    },
    startsAt() {
      return this.hasEvent ? this.event.starts_at : null;
    },
    endsAt() {
      return this.hasEvent ? this.event.ends_at : null;
    },
    isPast() {
      return this.hasEvent && this.endsAt && new Date(this.endsAt) < new Date();
    },
    status() {
      if (this.hasEvent) {
        if (this.event.is_cancelled) {
          return "cancelled";
        } else if (this.isPast) {
          return "past";
        } else {
          return this.event.is_virtual ? "online" : "in-person";
        }
      }
      return null;
    },
    spotsLeft() {
      if (this.hasEvent) {
        const spotsLeft = this.event.rsvp_limit !== null ? Math.max(0, this.event.rsvp_limit - this.event.rsvp_count.aggregate.count) : null;
        if (spotsLeft === null) return null;
        return spotsLeft;
      }
      return null;
    },
    venueName() {
      return this.hasEvent ? this.event.venue_json.physicalTitle || "Online" : "";
    },
    venueAddress() {
      return this.hasEvent ? this.event.venue_json.physicalAddress || "Online" : "";
    },
    venueLat() {
      return this.hasEvent ? this.event.place.lat : 0;
    },
    venueLng() {
      return this.hasEvent ? this.event.place.lng : 0;
    },
    onlineEventUrl() {
      return this.hasEvent && this.isVirtual ? this.event.venue_json.virtualUrl : null;
    },
    virtualMeetingId() {
      return this.hasEvent && this.isVirtual ? this.event.venue_json.virtualMeetingId : null;
    },
    virtualMeetingPassword() {
      return this.hasEvent && this.isVirtual ? this.event.venue_json.virtualMeetingPassword : null;
    },
    seriesId() {
      return this.hasEvent ? this.event.event_series.id : "";
    },
    organizerIds() {
      return this.hasEvent ? this.event.event_series.organizers.map(org => org.user_id) : [];
    },
    isOrganizer() {
      if (this.organizerIds && this.$auth.isAuthenticated) {
        return this.organizerIds.some(id => id === this.$auth.user.id);
      }
      return false;
    },
    hasRsvpAndFollowStatus() {
      return !!this.resultGetUserRsvpAndFollowStatus.id;
    },
    isRsvped() {
      return this.hasRsvpAndFollowStatus && this.resultGetUserRsvpAndFollowStatus.event_calendars.length > 0;
    },
    isFollowing() {
      return this.hasRsvpAndFollowStatus && this.resultGetUserRsvpAndFollowStatus.event_subscriptions.length > 0;
    },
    mapLink() {
      return this.hasEvent ? `https://www.google.com/maps/?q=${this.event.place.lat},${this.event.place.lng}` : null;
    },
    hasNextEvent() {
      return !!this.resultGetNextEventById?.id;
    },
    nextEventId() {
      return (this.hasNextEvent && this.resultGetNextEventById.event_series?.next_event?.[0]?.id) || null;
    },
    chapterSlug() {
      return this.hasNextEvent ? this.resultGetNextEventById.event_series?.chapter?.slug : null;
    },
    seriesSlug() {
      return this.hasNextEvent ? this.resultGetNextEventById.event_series?.slug : null;
    }
  },
  methods: {
    async onRSVPConfirm() {
      const userId = this.$auth.user.id;
      let result;
      if (this.isRsvped) {
        result = await SeriesApi.unrsvp(this.eventId, userId);
      } else {
        result = await SeriesApi.rsvp(this.eventId, userId);
      }
      if (result.success) {
        await this.$apollo.queries.resultGetUserRsvpAndFollowStatus.refetch();
        await this.$apollo.queries.resultGetEventCardById.refetch();
        this.$emit("toggle-rsvp");
        this.showModal = false;
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    onRsvpClick() {
      if (!this.$auth.isAuthenticated) {
        this.showAuthModal();
      } else {
        this.showRSVPModal();
      }
    },
    showAuthModal() {
      this.showModal = true;
      this.modalTitle = "You must be signed in to RSVP to an event. You will be redirected to the sign-in page.";
      this.modalConfirmAction = this.redirectToSignIn;
    },
    showRSVPModal() {
      const actionText = this.isRsvped ? "un-RSVP" : "RSVP";
      this.showModal = true;
      this.modalTitle = `Are you sure you want to ${actionText} to this event?`;
      this.modalConfirmAction = this.onRSVPConfirm;
    },
    redirectToSignIn() {
      this.$router.push({
        name: "SignIn",
        query: {
          redirectUrl: `${this.$route.fullPath}`
        }
      });
    },
    onEditEventClick() {
      this.$router.push({
        name: "EventEdit",
        params: {
          eventId: this.eventId,
          seriesId: this.seriesId
        }
      });
    },
    onNextEventClick() {
      if (!this.hasNextEvent) {
        return;
      }
      this.$router.push({
        name: "Series",
        params: {
          chapterSlug: this.chapterSlug,
          eventId: this.nextEventId,
          seriesSlug: this.seriesSlug
        }
      });
    },
    handleLinkCopied({ success }) {
      if (success) {
        this.$root.$emit("universal-success-message", "Link copied to clipboard successfully!");
      } else {
        this.$root.$emit("universal-error-message", "Failed to copy link to clipboard.");
      }
    },
    async onJoinClick() {
      if (!this.$auth.isAuthenticated) {
        this.redirectToSignIn();
        return;
      }
      if (this.onlineEventUrl) {
        window.open(this.onlineEventUrl, "_blank");
        await this.handleOnlineCheckIn();
      } else {
        this.$root.$emit("universal-error-message", "Meeting URL not available.");
      }
    },
    async handleOnlineCheckIn() {
      const result = await SeriesApi.checkIn(this.eventId, this.$auth.user.id);
      if (result.success) {
        this.$emit("online-check-in");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    onCancelModal() {
      this.showModal = false;
    }
  }
};
</script>

<template>
  <div>
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onCancelModal" />
    <SuawEmptyState v-if="isLoadingEventCard" message="Loading Event Card..." />
    <SuawEventCard
      v-else
      :is-virtual="isVirtual"
      :status="status"
      :is-rsvped="isRsvped"
      :is-following="isFollowing"
      :is-organizer="isOrganizer"
      :is-join-time="isJoinTime"
      :starts-at="startsAt"
      :ends-at="endsAt"
      :is-last-event="!hasNextEvent || seriesCancelled"
      :venue-title="venueName"
      :venue-address="venueAddress"
      :venue-lat="venueLat"
      :venue-lng="venueLng"
      :map-link="mapLink"
      :zoom="15"
      :spots-left="spotsLeft"
      :virtual-meeting-id="virtualMeetingId"
      :virtual-meeting-password="virtualMeetingPassword"
      @link-copied="handleLinkCopied"
      @edit-event-click="onEditEventClick"
      @rsvp-click="onRsvpClick"
      @join-click="onJoinClick"
      @next-event-click="onNextEventClick"
    />
  </div>
</template>
